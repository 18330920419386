import React, { useEffect, useState } from "react";

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(`${apiUrl}/get-projects.php`);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setProjects(data);
            } catch (error) {
                console.error(
                    "There was an error fetching the projects!",
                    error
                );
            }
        };

        fetchProjects();
    }, []);

    const handleCardClick = (project) => {
        if (project.title.toLowerCase().includes("préstamo")) {
            window.open("https://prestamo.limbermolina95.com/", "_blank");
        }
        if (project.title.toLowerCase().includes("inventario")) {
            window.open("https://taco-food.limbermolina95.com/", "_blank");
        }
        if (project.title.toLowerCase().includes("gallery")) {
            window.open(
                "https://limbervue.github.io/gallery_vuejs/#/gallery/personales",
                "_blank"
            );
        }
    };

    return (
        <div>
            <section className="projects">
                {projects.map((project) => (
                    <div key={project.id} className="project-card">
                        <div className="card-title">{project.title}</div>
                        <img
                            src={project.image_url}
                            alt={project.title}
                            className="card-image"
                        />
                        <div className="card-description">
                            {project.description}
                        </div>

                        <button
                            className="btn"
                            onClick={() => handleCardClick(project)}
                        >
                            Ver
                        </button>
                    </div>
                ))}
            </section>
        </div>
    );
};
export default Projects;
