import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import HeaderMobile from "./components/Header-mobile";
import Projects from "./components/Projects";
import AboutMe from "./components/About-me";
import Skills from "./components/Skills";
import "./App.css";

function App() {
    const [isMobile, setIsMobile] = useState(window.innerWidth >= 823);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth >= 823);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Router>
            <div className="app">
                {isMobile ? <Header /> : <HeaderMobile />}
                <Routes>
                    <Route path="/" element={<Navigate to="/projects" />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/about-me" element={<AboutMe />} />
                    <Route path="/skills" element={<Skills />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
